import angular from 'angular';
import DrugRow from './components/observations/drug-row.component';
import HospitalInformationDialog from './services/hospital-information-dialog/hospital-information-dialog.service';
import IllnessDialog from './services/illness-dialog/illness-dialog.service';
import InjuryDialog from './services/injury-dialog/injury-dialog.service';
import InterventionDialog from './services/intervention-dialog/intervention-dialog.service';
import Observations from './components/observations/observations.component';
import ObservationRow from './components/observations/observation-row.component';
import PatientDetails from './components/patient-details/patient-details.component';
import PatientDetailsDialog from './services/patient-details-dialog/patient-details-dialog.service';
import PRFListItem from './components/prf-list-item/prf-list-item.component';
import PRFApprovalDialog from './services/prf-approval-dialog/prf-approval-dialog.service';
import PRFResource from './resources/prf.resource';
import PrintablePRFResource from './resources/printable-prf.resource';
import SurveyItemDetails from './components/survey-item-details/survey-item-details.component';
import SurveyInterventionDetails from './components/survey-intervention-details/survey-intervention-details.component';
import SurveyResource from './resources/survey.resource';
import SurveySwitcher from './components/survey-switcher/survey-switcher.component';
import RecordDrugUseDialog from './services/record-drug-use-dialog/record-drug-use-dialog.service';
import ReviewDrugUseDialog from './services/review-drug-use-dialog/review-drug-use-dialog.service';

const module = angular
  .module('gnaas.core.prf', [])
  .component('drugRow', DrugRow)
  .component('observations', Observations)
  .component('observationRow', ObservationRow)
  .component('patientDetails', PatientDetails)
  .component('prfListItem', PRFListItem)
  .component('surveyItemDetails', SurveyItemDetails)
  .component('surveyInterventionDetails', SurveyInterventionDetails)
  .component('surveySwitcher', SurveySwitcher)
  .service('HospitalInformationDialog', HospitalInformationDialog)
  .service('IllnessDialog', IllnessDialog)
  .service('InjuryDialog', InjuryDialog)
  .service('InterventionDialog', InterventionDialog)
  .service('PatientDetailsDialog', PatientDetailsDialog)
  .service('PRFApprovalDialog', PRFApprovalDialog)
  .service('PRFResource', PRFResource)
  .service('PrintablePRFResource', PrintablePRFResource)
  .service('SurveyResource', SurveyResource)
  .service('RecordDrugUseDialog', RecordDrugUseDialog)
  .service('ReviewDrugUseDialog', ReviewDrugUseDialog);

export default module.name;
