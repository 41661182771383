export default {
  bindings: {
    crew: '<',
    vehicleCallsign: '<',
  },
  controller: function () {
    this.orderCrewMembers = (item) => {
      switch (item.type) {
        case 'pilot': return 1;
        case 'medic': return 2;
        case 'observer': return item.id ? 3 : 4;
      }
    };
  },
  templateUrl: 'core/deployments/components/deployment-crew/deployment-crew.tpl.html',
};
