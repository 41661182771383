export default function ModuleConfig ($stateProvider) {
  'ngInject';

  $stateProvider.state('reports', {
    url: '/reports',
    template: '<reports-page flex layout="column"></reports-page>',
    data: {
      title: 'Reports',
    },
  }).state('kpi-report', {
    url: '/kpi-report',
    template: '<kpi-report-page flex layout="column"></kpi-report-page>',
    data: {
      title: 'KPI Report',
    },
  });
}
