export default class IncidentPageService {

  /**
   * @constructor
   * @ngInject
   */
  constructor (
    $q, PrfPageService, AmbulanceServiceResource, DeploymentReasonResource, IncidentTypeResource,
    RefusalReasonResource, ServiceAreaResource, IncidentResource
  ) {
    this._q = $q;
    this._PrfPageService = PrfPageService;
    this._AmbulanceServiceResource = AmbulanceServiceResource;
    this._DeploymentReasonResource = DeploymentReasonResource;
    this._IncidentTypeResource = IncidentTypeResource;
    this._RefusalReasonResource = RefusalReasonResource;
    this._ServiceAreaResource = ServiceAreaResource;
    this._IncidentResource = IncidentResource;
  }

  getIncident (id) {
    return this._IncidentResource.show(id, {
      include: [
        'incidentType', 'incidentSubtype', 'serviceArea', 'refusalReason',
        'ambulanceService', 'deploymentReason', 'flaggedIncidents',
        'deployments.vehicleCallsign', 'deployments.deploymentStatuses',
        'deployments.deploymentStatuses.deploymentStatus', 'deployments.patientReportForms',
      ],
    });
  }

  getIncidentOutcome (incident) {
    if (!incident.deployments.length) {
      return;
    }

    let outcomes = [];

    incident.deployments.forEach(deployment => {
      deployment.deployment_statuses.forEach(status => {
        if (outcomes.includes(status.deployment_status.name)) {
          return;
        }

        if (status.deployment_status.is_stand_down ||
          status.deployment_status.is_diverted ||
          status.deployment_status.name.toLowerCase().indexOf('aborted') !== -1
        ) {
          outcomes.push(status.deployment_status.name);
        }
      });

      deployment.patient_report_forms.forEach(item => {
        let transportedTo = this._PrfPageService.getTransportedDestination(deployment.deployment_statuses);
        item.outcome = this._PrfPageService.getPRFOutcome(transportedTo);
        if (outcomes.includes(item.outcome)) {
          return;
        }

        outcomes.push(item.outcome);
      });
    });

    return this._generateOutcomeString(outcomes);
  }

  getRelationData () {
    return this._q.all({
      ambulanceServices: this._AmbulanceServiceResource.index(),
      deploymentReasons: this._DeploymentReasonResource.index(),
      incidentTypes: this._IncidentTypeResource.index(),
      refusalReasons: this._RefusalReasonResource.index(),
      serviceAreas: this._ServiceAreaResource.index(),
    }).then(r => {
      return Object.assign(r, {
        status: {
          'Accepted': 'accepted',
          'Declined': 'declined',
        },
      });
    }).catch(console.log.bind(console));
  }

  generateTimeline (times, deployments) {
    let timelineEvents = Object.keys(times).map(key => {
      return times[key];
    });

    if (deployments) {
      deployments.forEach(deployment => {
        deployment.deployment_statuses.forEach(status => {
          timelineEvents.push({
            label: deployment.vehicle_callsign.name + ' - ' + status.deployment_status.name,
            time: status.time,
          });
        });
      });
    }

    return timelineEvents;
  }

  _generateOutcomeString (outcomes) {
    let string = '';
    outcomes.forEach((outcome, i) => {
      string += outcome && i !== (outcomes.length - 1) ? outcome + ', ' : outcome;
    });
    return string;
  }
}
