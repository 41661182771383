import moment from 'moment';
import RestResource from 'common/resource/services/restResource';
import { format as formatVehicleCallsign } from 'pages/admin/vehicleCallsigns/services/vehicleCallsignResource';
import { format as formatVehicle } from 'pages/admin/vehicles/services/vehicleResource';
import { format as formatIncident } from 'core/incidents/resources/incident.resource';
import { format as formatCrewMember } from 'pages/crewMembers/services/crewMemberResource';
import { format as formatStatusUpdate } from 'core/deployments/resources/deployment-status-update.resource';
import formatPRF from 'core/prf/services/prf-formatter.service';

export function format (item) {
  let formatted = {
    id: item.id,
    date: moment(item.date),
  };

  if (item.vehicleCallsign) {
    formatted.vehicle_callsign = formatVehicleCallsign(item.vehicleCallsign.data);
  }

  if (item.vehicle) {
    formatted.vehicle = formatVehicle(item.vehicle.data);
  }

  if (item.incident) {
    formatted.incident = formatIncident(item.incident.data);
  }

  if (item.users) {
    formatted.crew_members = item.users.data.map(user => ({ ...formatCrewMember(user), type: user.type }));
  }

  if (item.deploymentStatuses) {
    formatted.deployment_statuses = item.deploymentStatuses.data.map(formatStatusUpdate);
  }

  if (item.patientReportForms) {
    formatted.patient_report_forms = item.patientReportForms.data.map(formatPRF);
  }

  return formatted;
}

export function parse (item) {
  let parsed = {
    id: item.id,
    date: moment(item.date).format('YYYY-MM-DD'),
    vehicle_callsign_id: item.vehicle_callsign.id,
    vehicle_id: item.vehicle_callsign.vehicle.id,
  };

  if (item.incident) {
    parsed.incident_id = item.incident.id;
  }

  parsed.users = item.crew_members.map(i => ({ id: i.id, type: i.type }));

  return parsed;
}

export default class DeploymentResource extends RestResource {
  init () {
    super.init();
    this.resourcePath = 'deployments';
  }

  updateStatus (id, deploymentStatus) {
    this._http.put(`${this.apiUrl}${this.resourcePath}/${id}`, {
      deployment_status_id: deploymentStatus.id,
      time: moment(deploymentStatus.time).format('YYYY-MM-DD HH:mm'),
    }).then(() => {
      return deploymentStatus;
    }).catch(console.log.bind(console));
  }

  format (item) {
    return format(item);
  }

  parse (item) {
    return parse(item);
  }
}
