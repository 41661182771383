import moment from 'moment';
import { format as formatDeployment } from 'core/deployments/resources/deployment.resource';
import { format as formatIncident } from 'core/incidents/resources/incident.resource';
import { format as formatStockTransactions } from 'pages/admin/drugs/resources/drug-stock-transaction.resource';
import formatSurvey from './survey-formatter.service';

export default function format (item) {
  let formatted = {
    id: item.id,
    reference_id: item.reference_id,
    first_name: item.first_name,
    last_name: item.last_name,
    gender: item.gender,
    patient_type: item.patientType ? item.patientType.data : null,
    weight: item.weight * 0.001,
    patient_consent_given: !!item.patient_consent_given,
    nhs_number: item.nhs_number,
    address: item.address,
    details: item.details,
    incident_description: item.incident_description,
    handover_notes: item.handover_notes,
    approval_status: item.approval_status,
    allergies: item.allergies,
  };

  if (item.date_of_birth) {
    formatted.date_of_birth = moment(item.date_of_birth, 'YYYY-MM-DD').toDate();
  }

  if (item.incident) {
    formatted.incident = formatIncident(item.incident.data);
  }

  if (item.deployment) {
    formatted.deployment = formatDeployment(item.deployment.data);
  }

  if (item.surveys) {
    formatted.surveys = item.surveys.data.map(formatSurvey);
  }

  if (item.approval) {
    formatted.approval = { id: item.approval.data.id };
    if (item.approval.data.firstUser) {
      formatted.approval.first_user = item.approval.data.firstUser.data;
    }
    if (item.approval.data.secondUser) {
      formatted.approval.second_user = item.approval.data.secondUser.data;
    }
  }

  if (item.stockTransactions) {
    formatted.stock_transactions = item.stockTransactions.data.map(formatStockTransactions);
  }

  if (item.images) {
    formatted.images = item.images.data;
  }

  if (item.attachments) {
    formatted.attachments = item.attachments.data;
  }

  return formatted;
}
