import RestResource from 'common/resource/services/restResource';
import { format as formatUser } from 'pages/crewMembers/services/crewMemberResource';

export function format (item) {
  const i = {
    id: item.id,
    user_id: item.user_id,
  };

  if (item.user) i.user = formatUser(item.user.data);

  return i;
}

export function parse (item) {
  return {
    id: item.id,
    user_id: item.user_id,
  };
}

export default class ReportablesResource extends RestResource {
  init () {
    super.init();
    this.resourcePath = 'alertable-users';
  }

  format (item) {
    return format(item);
  }

  parse (item) {
    return parse(item);
  }
}
