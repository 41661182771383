import moment from 'moment';
import uuid from 'node-uuid';
import ResourceDialogController from 'common/resource/controllers/resourceDialogController';

export default class PatientDetailsDialogController extends ResourceDialogController {
  init () {
    super.init();
    this._resource = this.$injector.get('PRFResource');
    this._state = this.$injector.get('$state');
    this.maxDateOfBirth = moment().toDate();
    this._ID = uuid.v4();
    this.genders = {
      'Female': 'female',
      'Male': 'male',
    };
  }

  create (data) {
    data.id = this._ID;
    data.incident = this.incident;
    data.deployment = this.deployment;
    data.date = this.incident.date;

    if (data.patient_consent_given == null) {
      data.patient_consent_given = 0;
    }

    data.nhs_number = data.nhs_number || null;
    data.details = data.details || null;
    data.incident_description = data.incident_description || null;
    data.handover_notes = data.handover_notes || null;
    data.allergies = data.allergies || null;

    this._resource.create(data).then(() => {
      this.Dialog.hide(data);
    }).catch(console.log.bind(console));
  }

  update (data) {
    this._resource.update(data.id, data).then(item => {
      this.Dialog.hide(item);
    });
  }

  delete (id) {
    this.Dialog.promptDelete().then(() => {
      this._resource.destroy(id)
        .then(() => {
          this.Dialog.cancel(id);
        })
        .catch(err => {
          let msg = 'Error deleting PRF.';
          if (err.status && err.status === 409) {
            msg = err.data.message;
          }
          this.Toast.showSimple(msg);
        });
    });
  }
}
