import moment from 'moment';
import parseAdministeredDrug from './administered-drug-parser.service';
import parseAssessedIllness from './assessed-illness-parser.service';
import parseAssessedInjury from './assessed-injury-parser.service';
import parseAdministeredIntervention from './administered-intervention-parser.service';

export default function parse (item) {
  const dateStr = moment(item.date).format('YYYY-MM-DD') + ' ' + moment(item.time).format('HH:mm:ss');

  let parsed = {
    id: item.id,
    time: dateStr,
  };

  if (item.patient_report_form) {
    parsed.patient_report_form_id = item.patient_report_form.id;
  }

  if (item.observations) {
    parsed.observations = item.observations;

    if (parsed.observations.pupil_size_left) {
      parsed.observations.pupil_size_left = parsed.observations.pupil_size_left == null
        ? null
        : parseInt(parsed.observations.pupil_size_left, 10);
    }

    if (parsed.observations.pupil_size_right) {
      parsed.observations.pupil_size_right = parsed.observations.pupil_size_right == null
        ? null
        : parseInt(parsed.observations.pupil_size_right, 10);
    }

    if (parsed.observations.blood_pressure) {
      parsed.observations.blood_pressure_1 = item.observations.blood_pressure.split('/')[0];
      parsed.observations.blood_pressure_2 = item.observations.blood_pressure.split('/')[1];
      delete parsed.observations.blood_pressure;
    }
  }

  if (item.administered_drugs) {
    parsed.administered_drugs = item.administered_drugs.map(parseAdministeredDrug);
  }

  if (item.assessed_injuries) {
    parsed.assessed_injuries = item.assessed_injuries.map(parseAssessedInjury);
  }

  if (item.assessed_illnesses) {
    parsed.assessed_illnesses = item.assessed_illnesses.map(parseAssessedIllness);
  }

  if (item.administered_interventions) {
    parsed.administered_interventions = item.administered_interventions.map(parseAdministeredIntervention);
  }

  return parsed;
}
