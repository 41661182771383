import moment from 'moment';
import { format as formatDeploymentStatusUpdate }
    from 'core/deployments/resources/deployment-status-update.resource.js';
import { DATE_FORMAT } from 'common/resource/module.constants';

function dateToString (date) {
  return moment(date || new Date()).format(DATE_FORMAT);
}

export class DashboardController {
  /**
   * @constructor
   * @ngInject
   */
  constructor (
    $q, $timeout, DashboardPageService, Dialog, Toast, Utils, IncidentPageService, ShiftCrewDialog, ShiftService,
    DeploymentStatusUpdateDialog, VehicleCallsignStatusUpdateDialog, APP_ORG_SHORT,
    Session
  ) {
    this._q = $q;
    this._timeout = $timeout;
    this._DashboardPageService = DashboardPageService;
    this._Dialog = Dialog;
    this._Utils = Utils;
    this._Toast = Toast;
    this._IncidentPageService = IncidentPageService;
    this._ShiftCrewDialog = ShiftCrewDialog;
    this._ShiftService = ShiftService;
    this._DeploymentStatusUpdateDialog = DeploymentStatusUpdateDialog;
    this._VehicleCallsignStatusUpdateDialog = VehicleCallsignStatusUpdateDialog;
    this._Session = Session;
    this.APP_ORG_SHORT = APP_ORG_SHORT;
  }

  $onInit () {
    this.today = dateToString();
    this.date = dateToString();
    this.dateString = 'Today';
    this._deploymentRelationData = {};
    this._vehicleCallsignRelationData = {};
    this._updateHeaderTimeout = null;

    this._updateHeaderTime();
    this._loadDashboardData();

    const user = this._Session.user();
    if (this.hasWritePermissions()) {
      this._loadRelationData();
    }
  }

  $onDestroy () {
    this._timeout.cancel(this._updateHeaderTimeout);
  }

  isAfterToday (date) {
    return moment(date).startOf('day').isAfter(moment(this.today).startOf('day'));
  }

  hasWritePermissions () {
    const user = this._Session.user();
    if (!user.group || !user.group) return false;
    return ['system-administrators', 'medical-staff', 'ops-admins', 'pilots'].indexOf(user.group.slug) > -1;
  }

  showStatusUpdateDialog ({$event, item}) {
    const deployments = item.deployments.map(deployment => {
      deployment.name = deployment.vehicle_callsign.name;
      return deployment;
    });

    const dialogParams = {
      $event: $event,
      relationData: this._deploymentRelationData,
      locals: {
        deployments: deployments,
      },
    };

    this._DeploymentStatusUpdateDialog.show(dialogParams).then(status => {
      deployments.some(item => {
        if (item.id !== status.deployment.id) {
          return false;
        }

        const i = this._getIndex(item.vehicle_callsign, this.vehicleCallsigns);
        const currentStatusTime = moment(this.vehicleCallsigns[i].time);
        const newStatusTime = status.time;

        if (newStatusTime.isAfter(currentStatusTime)) {
          this.vehicleCallsigns[i].lastDeployment = this._getVehicleCallsignDeploymentStatus(status);
        }

        item.deployment_statuses.push(status);

        return true;
      });

      this._Toast.showSimple('Deployment status updated.');
    });
  }

  /**
   * If date is passed in explicitly - it overrides data.time property if present.
   * Otherwise data.time is used if found, if not - current date.
   */
  showVehicleCallsignStatusUpdateDialog ({$event, data, date, vehicleCallsign}) {
    if (!date) {
      date = data ? data.time : new Date();
    }
    const time = moment(date).seconds(0).toDate();

    const dialogParams = {
      $event: $event,
      relationData: this._vehicleCallsignRelationData,
    };

    if (data && data.id) {
      dialogParams.item = {
        id: data.id,
        online: data.online ? 1 : 0,
        offline_reason: data.offlineReason,
        time,
        vehicleCallsign: data.vehicleCallsign,
      };
    } else {
      dialogParams.item = { time, vehicleCallsign };
      // Override editing, as passing in an item into ResourceDialog defaults editing to true
      dialogParams.locals = { editing: false };
    }

    this._VehicleCallsignStatusUpdateDialog.show(dialogParams).then(status => {
      if (!status) return;
      const i = this._getIndex(status, this.vehicleCallsignStatusUpdates);
      if (~i) {
        this.vehicleCallsignStatusUpdates[i] = status;
      } else {
        this.vehicleCallsignStatusUpdates = [...this.vehicleCallsignStatusUpdates, status];
      }
      this._Toast.showSimple('Vehicle status updated.');
    }).catch(id => {
      const i = this._getIndex({ id: id }, this.vehicleCallsignStatusUpdates);
      if (~i) {
        this.vehicleCallsignStatusUpdates.splice(i, 1);
        this._Toast.showSimple('Vehicle status deleted.');
      }
    }).finally(() => {
      this._generateTimeline();
      if (this.date === this.today) {
        this._DashboardPageService.getVehicleCallsign(dialogParams.item.vehicleCallsign.id).then(data => {
          this._updateVehicleCallsignStatus(data.id, data);
        });
      }
    });
  }

  currentDay () {
    if (this.date === this.today) {
      return;
    }
    this.date = dateToString();
    this.dateString = 'Today';
    this._loadDay(this.date);
  }

  changeDay (date) {
    if (dateToString(date) === this.date) {
      return;
    } else if (dateToString(date) === this.today) {
      this.currentDay();
      return;
    }
    this.date = dateToString(date);
    this.dateString = moment(date).format('DD-MM-YYYY').toString();
    this._loadDay(this.date);
  }

  showShiftCrewDialog ($event, shift) {
    this._ShiftCrewDialog.show({
      $event,
      locals: {
        shift: shift,
        crewMembers: this.crewMembers,
        date: shift.start_time,
        closeOnSubmit: true,
      },
    }).then(shiftCrew => {
      if (!moment(shift.start_time).isSame(shiftCrew.date, 'date')) return;
      shift.users = shiftCrew.users.map(i => ({ ...i, name: `${i.first_name} ${i.last_name}` }));
    });
  }

  _loadDay (date) {
    this._DashboardPageService.getCalendarEvents(date).then(data => (this.calendarEvents = data));
    this._DashboardPageService.getCalendarAgenda(date).then(data => (this.calendarAgenda = data));
    this.shifts = [];
    this._ShiftService.getShiftsForDate(date).then(data => (this.shifts = [...this.shifts, ...data]));
    this._ShiftService.getOvernightShiftsForDate(date).then(data => {
      const overnightShifts = data.map(shift => ({ ...shift, name: `${shift.name} (previous day)` }));
      this.shifts = [...this.shifts, ...overnightShifts];
    });
    this._loadTimelineData(date);
  }

  _updateHeaderTime () {
    this.currentTime = moment();
    this.today = dateToString(this.currentTime);
    const secDelay = (60 - this.currentTime.second()) * 1000;

    this._updateHeaderTimeout = this._timeout(() => {
      this._updateHeaderTime();
    }, secDelay);
  }

  _loadDashboardData () {
    this._loadDay(this.date);
    this._DashboardPageService.getBases().then(data => {
      // NOTE: Related to dashboard-page.service.js line 117
      this.bases = data.filter(base => base.has_crew);
      if (!this._deploymentRelationData.destinations) this._deploymentRelationData.destinations = {};
      this._deploymentRelationData.destinations.bases = data;
    });
    this._DashboardPageService.getCrewMembers().then(data => (this.crewMembers = data));
    this._DashboardPageService.getVehicleCallsigns().then(data => {
      this.vehicleCallsigns = data.map(this._getVehicleCallsignStatus);
      this._deploymentRelationData.vehicleCallsigns = data;
    });
  }

  _getVehicleCallsignStatus (item) {
    const status = item.latest_status_update;
    if (status) {
      const destination = status.destination ? ' - ' + status.destination.name : '';
      item.latestStatus = status.deployment_status.name + destination;
    } else {
      item.latestStatus = '-';
    }
    return item;
  }

  _loadTimelineData (date) {
    this._q.all({
      incidents: this._DashboardPageService.getIncidents(date),
      vehicleCallsignStatusUpdates: this._DashboardPageService.getVehicleCallsignStatusUpdates(date),
    }).then(data => {
      this._assignData(['incidents', 'vehicleCallsignStatusUpdates'], data);
      this._generateTimeline();
    });
  }

  _assignData (assignables, data) {
    const assignableData = {};
    Object.keys(data).filter(item => {
      if (assignables.indexOf(item) > -1) {
        assignableData[item] = data[item];
      }
    });
    Object.assign(this, assignableData);
  }

  _generateTimeline () {
    this.timelineEvents = [];

    this.incidents.forEach(incident => {
      incident.outcome = this._IncidentPageService.getIncidentOutcome(incident);
      this.timelineEvents.push({
        item: incident,
        type: 'incident',
        time: incident.times.call.time,
      });
    });

    this.vehicleCallsignStatusUpdates.forEach(status => {
      this.timelineEvents.push({
        item: status,
        type: 'callsign_status_update',
        time: status.time,
      });
    });

    this.timelineEvents.sort((a, b) => b.time - a.time);
  }

  _loadRelationData () {
    this._DashboardPageService.getDeploymentRelationData().then(data => {
      if (this._deploymentRelationData.destinations) {
        Object.assign(data.destinations, this._deploymentRelationData.destinations);
      }
      Object.assign(this._deploymentRelationData, data);
    });
    this._DashboardPageService.getVehicleCallsignRelationData().then(data => {
      Object.assign(this._vehicleCallsignRelationData, data);
    });
  }

  _getVehicleCallsignDeploymentStatus (status) {
    status.deployment = { data: status.deployment };
    const newStatus = formatDeploymentStatusUpdate(status);
    newStatus.deploymentStatus = status.deployment_status;
    newStatus.destination = status.destination;
    return newStatus;
  }

  _updateVehicleCallsignStatus (callsignId, status) {
    this.vehicleCallsigns = this.vehicleCallsigns.map(item => {
      if (callsignId === item.id) {
        item.online = status.online;
        item.statusString = item.online ? 'Online' : 'Offline';
      }
      return item;
    });
  }

  _getIndex (item, list) {
    return list.indexOf(this._Utils.findObjectInArrayById(list, item.id));
  }
};

export default {
  templateUrl: 'pages/dashboard/templates/dashboard-page.tpl.html',
  controller: DashboardController,
};
