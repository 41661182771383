import angular from 'angular';
import uiRouter from 'angular-ui-router';
import ModuleConfig from './module.config';
import kpiReportPage from './components/kpi-report-page/kpi-report-page';
import reportsPage from './components/reports-page/reports-page';

const module = angular
  .module('gnaas.pages.reports', [
    uiRouter,
  ])
  .config(ModuleConfig)
  .component('kpiReportPage', kpiReportPage)
  .component('reportsPage', reportsPage);

export default module.name;
