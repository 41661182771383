import uuid from 'node-uuid';

const ALERTABLE_TYPE_DRUG = 'drug';
const ALERTABLE_TYPE_INTERVENTION = 'intervention';

function parseSelected (type, items) {
  return items.map(item => {
    if (item.alertable_type) return item;
    return {
      id: uuid.v4(),
      alertable_id: item.alertable_id,
      alertable_type: type,
      alertable: { ...item },
    };
  });
}

class AlertablesPageController {
  /**
   * @constructor
   * @ngInject
   */
  constructor ($stateParams, AlertableResource, CheckboxListDialog, DrugResource, InterventionResource, Toast) {
    this.$stateParams = $stateParams;
    this.AlertableResource = AlertableResource;
    this.CheckboxListDialog = CheckboxListDialog;
    this.DrugResource = DrugResource;
    this.InterventionResource = InterventionResource;
    this.Toast = Toast;
  }

  $onInit () {
    this.type = this.$stateParams.type.toLowerCase();
    this.fields = {
      alertableName: 'Name',
    };

    if (!this.type || [ALERTABLE_TYPE_DRUG, ALERTABLE_TYPE_INTERVENTION].indexOf(this.type) < 0) {
      throw new Error(`Type not supported. Must be "${ALERTABLE_TYPE_DRUG}" or "${ALERTABLE_TYPE_INTERVENTION}`);
    }

    this.title = this.type === ALERTABLE_TYPE_DRUG ? 'Alertable Drugs' : 'Alertable Interventions';

    this.items = [];
    this.displayItems = [];
    this.AlertableResource.index({ include: 'alertable' }).then(this._setItems.bind(this));

    this.options = [];
    const resource = this.type === ALERTABLE_TYPE_DRUG ? this.DrugResource : this.InterventionResource;
    resource.index().then(items => {
      Object.assign(this.options, items.map(i => ({ ...i, alertable_id: i.id })));
    });
  }

  showSyncDialog ($event) {
    const params = {
      $event,
      resource: this.AlertableResource,
      options: this.options,
      items: this.items,
      parse: parseSelected.bind(null, this.type),
      title: this.title,
      trackByKey: 'alertable_id',
    };

    this.CheckboxListDialog
      .show(params)
      .then(items => {
        this._setItems(items);
        this.Toast.showSimple(params.title + ' updated');
      })
      .catch(err => {
        if (err && err.status) {
          this.Toast.showSimple('Error updating ' + params.title.toLowerCase());
        }
      });
  }

  _setItems (items) {
    this.items = items.map(i => ({ ...i, alertableName: i.alertable.name }));
    this.displayItems = this.items.filter(i => i.alertable_type === this.type);
  }
}

const AlertablesPage = {
  controller: AlertablesPageController,
  templateUrl: 'pages/admin/alerts/components/alertables-page/alertables-page.tpl.html',
};

export default AlertablesPage;
