export default function ($stateProvider) {
  'ngInject';

  $stateProvider
    .state('admin.alerts', {
      abstract: true,
      url: '/alerts',
      template: '<div ui-view layout="column"></div>',
    })
    .state('admin.alerts.alertables', {
      url: '/alertables/:type',
      controllerAs: '$ctrl',
      template: '<alertables-page layout="column"></alertables-page>',
      data: {
        title: 'Alertable Items',
      },
    })
    .state('admin.alerts.users', {
      url: '/users',
      controllerAs: '$ctrl',
      template: '<alertable-users-page layout="column"></alertable-users-page>',
      data: {
        title: 'Alertable Users',
      },
    });
};
