import RestResource from 'common/resource/services/restResource';

export function format (item) {
  let formatted = {
    id: item.id,
    name: item.name,
    is_trauma: !!item.is_trauma,
  };

  if (item.incidentSubtypes) {
    formatted.subtypes = item.incidentSubtypes.data.map(subtype => {
      subtype.is_penetrating_trauma = !!subtype.is_penetrating_trauma;
      return subtype;
    });
  }

  return formatted;
}

export function parse (item) {
  const parsed = {
    id: item.id,
    name: item.name,
    is_trauma: item.is_trauma ? 1 : 0,
  };

  parsed.subtypes = item.subtypes.map(subtype => {
    subtype.is_penetrating_trauma = subtype.is_penetrating_trauma ? 1 : 0;
    return subtype;
  });

  return parsed;
}

export default class IncidentTypeResource extends RestResource {
  init () {
    super.init();
    this.resourcePath = 'categories/incident-types';
  }

  parse (item) {
    return parse(item);
  }

  format (item) {
    return format(item);
  }
};
