import angular from 'angular';
import TextareaDialog from './textarea-dialog/textarea-dialog.service';
import Location from './location.service';

const module = angular
  .module('gnaas.common.services', [])
  .service('TextareaDialog', TextareaDialog)
  .service('Location', Location);

export default module.name;
