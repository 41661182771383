import moment from 'moment';
import L from 'leaflet';

moment.updateLocale('en', {
  week: {
    dow: 1,
  },
});

L.Icon.Default.imagePath = 'assets/images/leaflet/';

export default function AppConfig ($urlRouterProvider, $httpProvider, $mdThemingProvider,
    $mdDateLocaleProvider, JwtInterceptorProvider, $mdGestureProvider) {
  'ngInject';

  /**
   * ROUTING
   */
  $urlRouterProvider.otherwise('/');

  /**
   * HTTP
   */
  // $httpProvider.defaults.withCredentials = true
  $httpProvider.defaults.headers.common.Accept = 'application/x.arc-ems.v1+json';
  $httpProvider.defaults.headers.post['Content-Type'] = 'application/json';
  $httpProvider.defaults.headers.put['Content-Type'] = 'application/json';
  $httpProvider.interceptors.push(['$injector', function ($injector) {
    return $injector.get('JwtInterceptor');
  }]);

  /**
   * Locale
   */
  $mdDateLocaleProvider.formatDate = function (date) {
    return date ? moment(date).format('DD-MM-YYYY') : '';
  };

  $mdDateLocaleProvider.parseDate = function (dateString) {
    let m = moment(dateString, 'DD-MM-YYYY', true);
    return m.isValid() ? m.toDate() : new Date(NaN);
  };

  $mdGestureProvider.skipClickHijack();

  /**
   * THEMING
   *
   * For primary & warn:
   * 500 - default
   * 300 - hue 1
   * 800 - hue 2
   * A100 - hue 3
   *
   * For accent:
   * A200 - default
   * A100 - hue 1
   * A400 - hue 2
   * A700 - hue 3
   *
   * For background:
   * 50 - default
   * 800 - dark default
   */
  let gnaasPrimary = $mdThemingProvider.extendPalette('green', {
    '500': '268339',
    '300': '5DBA61',
    '800': '00550E',
    'A100': 'FFFFFF',
    'contrastDefaultColor': 'light',
    'contrastDarkColors': ['A100'],
  });
  $mdThemingProvider.definePalette('gnaasPrimary', gnaasPrimary);

  let gnaasAccent = $mdThemingProvider.extendPalette('amber', {
    'A200': 'FFED02',
    // 'A100': '',
    'A400': 'CFCFCF',
    'A700': '838383',
    'contrastLightColors': ['A700'],
  });
  $mdThemingProvider.definePalette('gnaasAccent', gnaasAccent);

  let gnaasBackground = $mdThemingProvider.extendPalette('grey', {
    '50': 'FFFFFF',
    '800': '282828',
  });
  $mdThemingProvider.definePalette('gnaasBackground', gnaasBackground);

  $mdThemingProvider.theme('default')
    .primaryPalette('gnaasPrimary')
    .accentPalette('gnaasAccent')
    .warnPalette('red')
    .backgroundPalette('gnaasBackground');

  $mdThemingProvider.theme('dark').dark();
}
