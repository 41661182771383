export class AuditToolController {
  /**
   * @constructor
   * @ngInject
   */
  constructor (Dialog, ColumnsToDisplayDialog, Toast, AuditToolResource) {
    this._Dialog = Dialog;
    this._ColumnsToDisplayDialog = ColumnsToDisplayDialog;
    this._Toast = Toast;
    this._AuditToolResource = AuditToolResource;
  }

  $onInit () {
    this.selectedTab = 'search';
    this.showLoader = false;
    this.queryFailed = false;
    this.query = {};
    this.loadSearchCriteria();
  }

  selectTab (tab) {
    if (!(tab === 'search' || tab === 'savedSearches')) {
      throw new Error('Tab name must be "search" or "savedSearches".');
    }
    this.selectedTab = tab;
  }

  changeCategory ({ name }) {
    this.selectedCategoryName = name;
    this.selectedCategory = this.categories[name];
  }

  search ({criteria, columnsToDisplay}) {
    this._Toast.showSimple('Searching...');
    this.showLoader = true;
    this.queryFailed = false;

    this._AuditToolResource
      .search(criteria, columnsToDisplay)
      .then(data => {
        this.searchResults = data;
      })
      .catch(() => {
        this.queryFailed = true;
      })
      .finally(() => (this.showLoader = false));
  }

  loadSearchCriteria () {
    this.categories = [];
    this.operations = [];
    this.values = [];
    this.showLoader = true;
    this._AuditToolResource.getCategories().then(data => {
      this.categories = data.categories;
      this.operations = data.operations;
      this.values = data.values;
      this.changeCategory({ name: Object.keys(data.categories)[0] });
    }).finally(() => (this.showLoader = false));
  }

  /*
  saveSearch () {
    if (!this.query.length) {
      return;
    }

    this._Toast.showSimple('Saving search...');
  }
  */
}

export default {
  controller: AuditToolController,
  templateUrl: 'pages/audit-tool/components/audit-tool-page/audit-tool-page.tpl.html',
};
