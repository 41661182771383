import moment from 'moment';

function prettify (str) {
  return str.split('_').map(item => item).join(' ').toUpperCase();
}

export class KpiReportPageController {
  /**
   * @constructor
   * @ngInject
   */
  constructor ($state, KpiReportResource, Dialog) {
    this.$state = $state;
    this.KpiReportResource = KpiReportResource;
    this.Dialog = Dialog;
  }

  $onInit () {
    this.data = null;
    this.dataToDisplay = {};
    this.maxDate = new Date();
    this.showLoader = false;
  }

  search (dateFrom, dateTo) {
    if (!this.dateFrom || !this.dateTo) {
      this.Dialog.alert('Please set a date range.');
      return;
    }

    const from = moment(this.dateFrom).format('YYYY-MM-DD');
    const to = moment(this.dateTo).format('YYYY-MM-DD');
    this.showLoader = true;
    this.KpiReportResource.get(from, to).then(data => {
      this.showLoader = false;
      this.data = data;
      this.dataToDisplay = Object.entries(this.data).reduce((acc, cur) => {
        return [
          ...acc,
          {
            name: prettify(cur[0]),
            isObject: typeof cur[1] === 'object',
            value: typeof cur[1] === 'object'
              ? Object.entries(cur[1]).reduce((acc, cur) => ({ ...acc, [cur[0].replace('_to_', '-')]: cur[1] }), {})
              : cur[1],
          },
        ];
      }, []);
    }).catch(() => {
      this.showLoader = false;
    });
  }

  exportCsv () {
    if (!this.data) return;
    const data = Object.entries(this.data).reduce((acc, cur) => {
      if (typeof cur[1] !== 'object') {
        return { ...acc, [cur[0]]: cur[1] };
      }

      return {
        ...acc,
        ...Object.entries(cur[1]).reduce((accInner, curInner) => {
          return { ...accInner, [`${cur[0]}_${curInner[0]}`]: curInner[1] };
        }, {}),
      };
    }, {});
    const keys = Object.keys(data);
    const values = Object.values(data);
    const csvContent = [keys, values].reduce((acc, cur) => `${acc}${cur.join(',')}\r\n`, '');
    this._downloadCsv(csvContent);
  }

  _downloadCsv (csvContent) {
    const encodedUri = 'data:text/csv;charset=utf-8,' + encodeURI(csvContent);
    const from = moment(this.dateFrom).format('YYYY-MM-DD');
    const to = moment(this.dateTo).format('YYYY-MM-DD');

    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', `kpi_${from}_${to}.csv`);
    link.setAttribute('target', '_blank');

    document.body.appendChild(link); // Required for FF
    link.click();
    document.body.removeChild(link); // Required for FF
  }
}

export default {
  controller: KpiReportPageController,
  templateUrl: 'pages/reports/components/kpi-report-page/kpi-report-page.tpl.html',
};
