import uuid from 'node-uuid';

function parseSelected (items) {
  return items.map(item => {
    if (item.user) return item;
    const original = { ...item };
    return {
      id: uuid.v4(),
      user_id: item.user_id,
      user: original,
    };
  });
}

function getUsersFullName (user) {
  return user.first_name + ' ' + user.last_name;
}

class AlertableUsersPageController {
  /**
   * @constructor
   * @ngInject
   */
  constructor (AlertableUserResource, CheckboxListDialog, CrewMemberResource, Toast) {
    this.AlertableUserResource = AlertableUserResource;
    this.CheckboxListDialog = CheckboxListDialog;
    this.CrewMemberResource = CrewMemberResource;
    this.Toast = Toast;
  }

  $onInit () {
    this.fields = {
      userName: 'Name',
    };

    this.items = [];
    this.AlertableUserResource.index({ include: 'user' }).then(this._setItems.bind(this));

    this.options = [];
    this.CrewMemberResource.index().then(items => {
      Object.assign(this.options, items.map(i => ({ ...i, user_id: i.id, name: getUsersFullName(i) })));
    });
  }

  showSyncDialog ($event) {
    const params = {
      $event,
      resource: this.AlertableUserResource,
      options: this.options,
      items: this.items,
      parse: parseSelected,
      title: this.title,
      trackByKey: 'user_id',
    };

    this.CheckboxListDialog
      .show(params)
      .then(items => {
        this._setItems(items);
        this.Toast.showSimple(params.title + ' updated');
      })
      .catch(err => {
        if (err && err.status) {
          this.Toast.showSimple('Error updating ' + params.title.toLowerCase());
        }
      });
  }

  _setItems (items) {
    this.items = items.map(i => ({ ...i, userName: getUsersFullName(i.user) }));
  }
}

const AlertableUsersPage = {
  controller: AlertableUsersPageController,
  templateUrl: 'pages/admin/alerts/components/alertable-users-page/alertable-users-page.tpl.html',
};

export default AlertableUsersPage;
