import moment from 'moment';

class ReportsPage {
  /**
   * @constructor
   * @ngInject
   */
  constructor (ReportResource, Toast, VehicleCallsignResource, $scope) {
    this._ReportResource = ReportResource;
    this._Toast = Toast;
    this._VehicleCallsignResource = VehicleCallsignResource;
    this._$scope = $scope;
  }

  $onInit () {
    this.maxEndDate = new Date();
    this.minEndDate = null;
    this.maxStartDate = moment(this.maxEndDate).subtract(2, 'days').toDate();
    this._VehicleCallsignResource.index().then(data => (this.vehicleCallsigns = data));
    this.showLoader = false;

    this._$scope.$watch(() => this.toDate, (newVal) => {
      if (!newVal) return;
      this.maxStartDate = moment(newVal).subtract(2, 'days').toDate();
    });

    this._$scope.$watch(() => this.fromDate, (newVal) => {
      if (!newVal) return;
      this.minEndDate = moment(newVal).add(2, 'days').toDate();
    });
  }

  download (fromDate, toDate, vehicleCallsignId = null) {
    if (this.form.$invalid || !fromDate || !toDate) return;

    const from = moment(fromDate).startOf('day').format('YYYY-MM-DD');
    const to = moment(toDate).endOf('day').format('YYYY-MM-DD');

    this.showLoader = true;

    this._ReportResource
      .download({from, to, vehicleCallsignId, csv: true})
      .then(data => {
        const encodedUri = 'data:text/csv;charset=utf-8,' + encodeURI(data);

        const link = document.createElement('a');
        link.setAttribute('href', encodedUri);
        link.setAttribute('download', `report_${from}_-_${to}.csv`);
        link.setAttribute('target', '_blank');

        document.body.appendChild(link); // Required for FF
        link.click();
        document.body.removeChild(link); // Required for FF
        this.showLoader = false;
      })
      .catch(() => {
        this._Toast.showSimple('Error downloading report.');
        this.showLoader = false;
      });
  }
}

export default {
  controller: ReportsPage,
  templateUrl: 'pages/reports/components/reports-page/reports-page.tpl.html',
};
