import moment from 'moment';
import RestResource from 'common/resource/services/restResource';
import { format as formatCrewMember } from 'pages/crewMembers/services/crewMemberResource';
import { format as formatBase } from 'pages/admin/bases/services/baseResource';
import { format as formatVehicleCallsign } from 'pages/admin/vehicleCallsigns/services/vehicleCallsignResource';
import { DATE_FORMAT, TIME_FORMAT } from 'common/resource/module.constants';

/**
 * Creates a moment instance from time & applies date changes if set.
 *
 * @param time Time string
 * @param date Date string
 * @returns {moment.Moment}
 */
function setDate (time, date) {
  const dateTime = moment(time, TIME_FORMAT);
  if (date) {
    date = moment(date);
    dateTime.year(date.year()).month(date.month()).date(date.date());
  }
  return dateTime;
}

export function format (item, date) {
  const i = {
    id: item.id,
    name: item.name,
    start_date: item.start_date ? moment(item.start_date, DATE_FORMAT).toDate() : null,
    end_date: item.end_date ? moment(item.end_date, DATE_FORMAT).toDate() : null,
    start_time: null,
    end_time: null,
  };

  if (item.start_time) {
    const startTime = setDate(item.start_time, date);
    i.start_time = startTime.toDate();
  }

  if (item.end_time) {
    const endTime = setDate(item.end_time, date);
    if (i.start_time && endTime.isBefore(i.start_time)) {
      endTime.add(1, 'day');
    }
    i.end_time = endTime.toDate();
  }

  if (item.base && !Array.isArray(item.base.data)) i.base = formatBase(item.base.data);

  if (item.vehicleCallsign && !Array.isArray(item.vehicleCallsign.data)) {
    i.vehicle_callsign = formatVehicleCallsign(item.vehicleCallsign.data);
  }

  if (item.users) i.users = item.users.data.map(formatShiftUser);

  return i;
}

export function parse (item) {
  return {
    id: item.id,
    name: item.name,
    start_date: item.start_date ? moment(item.start_date).format(DATE_FORMAT) : null,
    end_date: item.end_date ? moment(item.end_date).format(DATE_FORMAT) : null,
    start_time: moment(item.start_time).format('HH:mm'),
    end_time: moment(item.end_time).format('HH:mm'),
    base_id: item.base ? item.base.id : null,
    vehicle_callsign_id: item.vehicle_callsign ? item.vehicle_callsign.id : null,
  };
}

export function formatShiftUser (item) {
  return {
    ...formatCrewMember(item),
    date: moment(item.date, DATE_FORMAT).toDate(),
    type: item.type ? item.type : null,
  };
}

export function parseShiftUsers (item) {
  return {
    date: moment(item.date).format(DATE_FORMAT),
    users: Array.isArray(item.users) ? item.users.map(i => ({ id: i.id, type: i.type ? i.type : null })) : [],
  };
}

/**
 * ShiftResource overrides multiple base methods to allow incorporating date manipulation
 * on certain properties.
 * Shifts reflect data based on the 'date' property, if added to query string. This in turn
 * needs to be reflected locally for more accurate data.
 */
export default class ShiftResource extends RestResource {
  init () {
    super.init();
    this.resourcePath = 'shifts';
  }

  /**
   * @override
   */
  index (query) {
    let url = this.apiUrl + this.resourcePath;

    if (query) {
      url += this._generateQueryString(query);
    }

    return this._http.get(url).then(response => {
      return response.data ? this._formatData(response.data, query ? query.date : null) : [];
    });
  }

  /**
   * @override
   */
  show (id, query) {
    let url = `${this.apiUrl}${this.resourcePath}/${id}`;

    if (query) {
      url += this._generateQueryString(query);
    }

    return this._http.get(url).then(response => {
      return response.data.data ? this._formatData(response.data.data, query ? query.date : null) : null;
    });
  }

  /**
   * @override
   */
  update (id, data, query) {
    let url = `${this.apiUrl}${this.resourcePath}/${id}`;

    if (query) {
      url += this._generateQueryString(query);
    }

    let parsed = this._parseData(data);
    if (parsed.id) {
      delete parsed.id;
    }

    return this._http.put(url, parsed).then(response => {
      return response.data.data ? this._formatData(response.data.data, query ? query.date : null) : null;
    });
  }

  /**
   * @override
   * @param date Date the items are referring to
   */
  _formatData (data, date) {
    if (Array.isArray(data)) {
      return data.map(item => this.format(item, date));
    }

    if (data.data && data.meta && data.meta.pagination) {
      return {
        data: data.data.map(item => this.format(item, date)),
        pagination: data.meta.pagination,
      };
    }

    if (data.data) {
      return Array.isArray(data.data) ? data.data.map(item => this.format(item, date)) : this.format(data.data, date);
    }

    return this.format(data, date);
  }

  format (item, date) {
    return format(item, date);
  }

  parse (item) {
    return parse(item);
  }

  setUsers (id, data, query) {
    let url = `${this.apiUrl}${this.resourcePath}/${id}/users`;
    if (query) {
      url += this._generateQueryString(query);
    }
    return this._http.put(url, parseShiftUsers(data)).then(response => {
      if (!response.data.data) return [];
      return response.data.data.map(formatShiftUser);
    });
  }

  getUsers (id, query) {
    let url = `${this.apiUrl}${this.resourcePath}/${id}/users`;
    if (query) {
      url += this._generateQueryString(query);
    }
    return this._http.get(url).then(response => {
      if (!response.data.data) return [];
      return response.data.data.map(formatShiftUser);
    });
  }
}
