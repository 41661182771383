import angular from 'angular';
import uiRouter from 'angular-ui-router';
import ModuleConfig from './module.config';
import CATEGORY_MAP from './category-map';

import CreateBodyPartController from './controllers/create-body-part.controller';
import CreateCategoryController from './controllers/create-category.controller';
import CreateCalendarEventTypeController from './controllers/create-calendar-event-type.controller';
import CreateIncidentTypeController from './controllers/create-incident-type.controller';
import CreateCategoryWithTypesController from './controllers/create-category-with-types.controller';
import CreateDeploymentStatusController from './controllers/create-deployment-status.controller';
import CreateInterventionController from './controllers/create-intervention.controller';

import CategoryItems from './components/category-items/category-items.component';

const module = angular
  .module('gnaas.pages.admin.categories', [
    uiRouter,
  ])
  .constant('CATEGORY_MAP', CATEGORY_MAP)
  .config(ModuleConfig)
  .controller('CreateBodyPartController', CreateBodyPartController)
  .controller('CreateCategoryController', CreateCategoryController)
  .controller('CreateCalendarEventTypeController', CreateCalendarEventTypeController)
  .controller('CreateIncidentTypeController', CreateIncidentTypeController)
  .controller('CreateCategoryWithTypesController', CreateCategoryWithTypesController)
  .controller('CreateDeploymentStatusController', CreateDeploymentStatusController)
  .controller('CreateInterventionController', CreateInterventionController)
  .component('categoryItems', CategoryItems);

export default module.name;
