import moment from 'moment';

function getColumnWidth (len) {
  if (len < 5) return len * 20;
  if (len < 10) return len * 15;
  if (len > 19) return len * 11;
  if (len > 13) return len * 14;
  return len * 15;
}

function prettify (str) {
  return str.split('_').map(item => ucFirst(item)).join(' ');
}

function ucFirst (str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export class KPIPageController {
  /**
   * @constructor
   * @ngInject
   */
  constructor ($window, $state, KpiResource, Dialog, Toast) {
    this.$window = $window;
    this.$state = $state;
    this.KpiResource = KpiResource;
    this.Dialog = Dialog;
    this.Toast = Toast;
  }

  $onInit () {
    this.showLoader = false;
    this.data = null;
    this.columns = [];
    this.options = {
      enablePagination: true,
      totalItems: 0,
      pageNumber: 1,
    };
    this.actionColumn = {
      options: {
        name: 'actions',
        displayName: 'Actions',
        width: 264,
      },
      actions: [
        {
          name: 'Incident',
          callback: (entity) => {
            const url = this.$state.href('incident', { id: entity.incident_id });
            this.$window.open(url, '_blank');
          },
        },
        {
          name: 'Deployment',
          callback: (entity) => {
            if (!entity.deployment_id) return;
            const url = this.$state.href('deployments', { id: entity.deployment_id });
            this.$window.open(url, '_blank');
          },
          isHidden: (entity) => {
            return !entity.deployment_id;
          },
        },
        {
          name: 'PRF',
          callback: (entity) => {
            if (!entity.prf_id) return;
            const url = this.$state.href('prf.patientDetails', { id: entity.prf_id });
            this.$window.open(url, '_blank');
          },
          isHidden: (entity) => {
            return !entity.prf_id;
          },
        },
      ],
    };
    this.maxDate = new Date();
  }

  search (dateFrom, dateTo) {
    if (!this.dateFrom || !this.dateTo) {
      this.Dialog.alert('Please set date range.');
      return;
    }

    const from = moment(this.dateFrom).format('YYYY-MM-DD');
    const to = moment(this.dateTo).format('YYYY-MM-DD');
    this.showLoader = true;
    this.KpiResource.get(from, to).then(data => {
      this.columns = data.length ? this._getColumnDefs(data[0]) : [];
      this.options = { ...this.options, pageNumber: 1, totalItems: data.length };
      this.data = data;
      this.showLoader = false;
    }).catch(() => {
      this.Toast.showSimple('Error retrieving KPI data.');
      this.showLoader = false;
    });
  }

  exportCsv () {
    if (!this.data.length) return;
    const keys = Object.keys(this.data[0]).filter(k => k !== '$$hashKey');
    const data = this.data.map(item => {
      return Object.entries(item)
        .filter(([k]) => k !== '$$hashKey')
        // Enclose value in double quotes, to prevent data splitting incorrectly in case a comma is present id value
        .map(([k, v]) => v === null ? '""' : `"${v}"`);
    });
    const csvContent = [keys, ...data].reduce((acc, cur) => `${acc}${cur.join(',')}\r\n`, '');
    this._downloadCsv(csvContent);
  }

  _downloadCsv (csvContent) {
    const encodedUri = 'data:text/csv;charset=utf-8,' + encodeURI(csvContent);
    const from = moment(this.dateFrom).format('YYYY-MM-DD');
    const to = moment(this.dateTo).format('YYYY-MM-DD');

    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', `kpi_${from}_${to}.csv`);
    link.setAttribute('target', '_blank');

    document.body.appendChild(link); // Required for FF
    link.click();
    document.body.removeChild(link); // Required for FF
  }

  _getColumnDefs (row) {
    return Object.keys(row).reduce((acc, cur) => ({ ...acc, [cur]: this._getColumnDef(cur) }), {});
  }

  _getColumnDef (key) {
    const name = prettify(key);
    return { name, width: getColumnWidth(name.length) };
  }
}

export default {
  controller: KPIPageController,
  templateUrl: 'pages/audit-tool/components/kpi-page/kpi-page.tpl.html',
};
