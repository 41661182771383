import CreateDrugController from '../services/drug-dialog/drug-dialog.controller';
import { RESOURCE_ITEM_FIELD_TYPES } from 'common/resource/module.constants';

const DEFAULT_LIST_FIELDS = {
  name: 'Name',
  concentration: 'Concentration',
};

const DEFAULT_PROFILE_FIELDS = [
  {
    key: 'concentration',
    label: 'Concentration',
    type: RESOURCE_ITEM_FIELD_TYPES.item,
  },
  {
    key: 'dose_per_unit',
    label: 'Total Dose Per Unit',
    type: RESOURCE_ITEM_FIELD_TYPES.item,
  },
  {
    key: 'measurement_type',
    label: 'Measurement Type',
    type: RESOURCE_ITEM_FIELD_TYPES.item,
  },
  {
    key: 'form',
    label: 'Form',
    type: RESOURCE_ITEM_FIELD_TYPES.item,
  },
  {
    key: 'unit_volume',
    label: 'Unit Volume',
    type: RESOURCE_ITEM_FIELD_TYPES.item,
  },
  {
    key: 'is_controlled_drug',
    label: 'Controlled Drug',
    type: RESOURCE_ITEM_FIELD_TYPES.radio,
  },
  {
    key: 'exclude_from_stock',
    label: 'Exclude From Stock',
    type: RESOURCE_ITEM_FIELD_TYPES.radio,
  },
];

export default function DrugListController (DrugResource, SupplierResource, StockLocationResource) {
  'ngInject';

  this.singularName = 'Drug';
  this.pluralName = 'Drugs';

  this.createDialogController = CreateDrugController;
  this.createDialogTemplateUrl = 'pages/admin/drugs/services/drug-dialog/drug-dialog.tpl.html';
  DrugResource
    .index({ include: 'productCodes.supplier,stockLocationProperties' })
    .then(items => { this.items = items; });

  this.relationData = { drugMeasurementTypes: [], stockLocations: [], suppliers: [] };
  DrugResource.getMeasurementTypes().then(items => { this.relationData.drugMeasurementTypes = items; });
  SupplierResource.index().then(items => { this.relationData.suppliers = items; });
  StockLocationResource.index().then(items => { this.relationData.stockLocations = items; });

  this.hasProfiles = true;
  this.listFields = Object.assign({}, DEFAULT_LIST_FIELDS);
  this.profileFields = [...DEFAULT_PROFILE_FIELDS];
};
