import moment from 'moment';

export default function parse (item) {
  const dateStr = moment(item.date).format('YYYY-MM-DD') + ' ' + moment(item.time).format('HH:mm:ss');

  return {
    id: item.id,
    drug_id: item.drug.id,
    dose: item.dose,
    time: dateStr,
  };
}
