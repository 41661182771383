import RestResource from 'common/resource/services/restResource';
import { format as formatDrug } from 'pages/admin/drugs/resources/drug.resource';
import { format as formatIntervention } from 'core/categories/resources/intervention.resource';

export function format (item) {
  const i = {
    id: item.id,
    alertable_id: item.alertable_id,
    alertable_type: item.alertable_type,
  };

  if (item.alertable) {
    switch (item.alertable_type) {
      case 'drug':
        i.alertable = formatDrug(item.alertable.data);
        break;
      case 'intervention':
        i.alertable = formatIntervention(item.alertable.data);
        break;
      default:
        i.alertable = null;
        break;
    }
  }

  return i;
}

export function parse (item) {
  return {
    id: item.id,
    alertable_id: item.alertable_id,
    alertable_type: item.alertable_type,
  };
}

export default class AlertableResource extends RestResource {
  init () {
    super.init();
    this.resourcePath = 'alertables';
  }

  format (item) {
    return format(item);
  }

  parse (item) {
    return parse(item);
  }
}
