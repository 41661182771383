import angular from 'angular';
import AlertableResource from './resources/alertable.resource';
import AlertableUserResource from './resources/alertable-users.resource';

const module = angular
  .module('gnaas.core.alerts', [])
  .service('AlertableResource', AlertableResource)
  .service('AlertableUserResource', AlertableUserResource);

export default module.name;
