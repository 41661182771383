import uuid from 'node-uuid';

export default class FlagIncidentDialogController {
  /**
   * @constructor
   * @ngInject
   */
  constructor (Dialog, FlaggedIncidentResource) {
    this.Dialog = Dialog;
    this.Resource = FlaggedIncidentResource;
    this._ID = uuid.v4();

    if (!this.item) {
      this.item = {
        incident: this.locals.incident,
      };
    }
  }

  submit (formData) {
    if (!this.form || this.form.$invalid) {
      return;
    }

    this.Dialog.confirm('Are you sure you want to flag this incident?').then(() => {
      formData.id = this._ID;
      formData.flagged_on_time = new Date();

      this.Resource
        .create(formData)
        .then(() => {
          this.Dialog.hide(formData);
        });
    });
  }

  cancel () {
    this.Dialog.cancel();
  }
}
