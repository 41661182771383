import moment from 'moment';
import RestResource from 'common/resource/services/restResource';
import { format as formatDeployment } from 'core/deployments/resources/deployment.resource';
import { format as formatFlaggedIncident } from 'core/incidents/resources/flagged-incident.resource';

export function parse (item) {
  let parsed = {
    id: item.id,
    incident_number: item.incident_number,
    date: moment(item.date).format('YYYY-MM-DD'),
    ambulance_service_number: item.ambulance_service_number,
    status: item.status,
    major_incident: item.major_incident ? item.major_incident : 0,
    comment: item.comment || null,
    location: item.location,
  };

  parsed.times = {};
  if (item.times.emergency.time) {
    parsed.times.emergency = moment(item.times.emergency.time).format('YYYY-MM-DD HH:mm:ss');
  }
  if (item.times.call.time) {
    parsed.times.call = moment(item.times.call.time).format('YYYY-MM-DD HH:mm:ss');
  }
  if (item.times.accepted.time) {
    parsed.times.accepted = moment(item.times.accepted.time).format('YYYY-MM-DD HH:mm:ss');
  }
  if (item.times.refused.time) {
    parsed.times.refused = moment(item.times.refused.time).format('YYYY-MM-DD HH:mm:ss');
  }

  if (item.deployment_reason) {
    parsed.deployment_reason_id = item.deployment_reason.id;
  }

  if (item.incident_type) {
    parsed.incident_type_id = item.incident_type.id;
  }

  parsed.incident_subtype_id = item.incident_subtype ? item.incident_subtype.id : null;

  if (item.service_area) {
    parsed.service_area_id = item.service_area.id;
  }

  if (item.ambulance_service) {
    parsed.ambulance_service_id = item.ambulance_service.id;
  }

  if (item.refusal_reason) {
    parsed.refusal_reason_id = item.refusal_reason.id;
  }

  return parsed;
}

export function format (item) {
  let formatted = {
    id: item.id,
    incident_number: item.incident_number,
    date: item.hasOwnProperty('date') ? moment(item.date, 'YYYY-MM-DD') : null,
    status: item.hasOwnProperty('status') ? item.status : null,
    major_incident: item.hasOwnProperty('major_incident') ? !!item.major_incident : null,
    ambulance_service_number: item.hasOwnProperty('ambulance_service_number') ? item.ambulance_service_number : null,
    comment: item.hasOwnProperty('comment') ? item.comment : null,
  };

  if (item.location) {
    formatted.location = {
      lat: item.location.lat,
      lng: item.location.lng,
      location: item.location.location,
    };
  }

  if (item.times) {
    formatted.times = {};
    if (item.times.emergency) { formatted.times.emergency = item.times.emergency; }
    if (item.times.call) { formatted.times.call = item.times.call; }
    if (item.times.accepted) { formatted.times.accepted = item.times.accepted; }
    if (item.times.refused) { formatted.times.refused = item.times.refused; }
    Object.keys(formatted.times).forEach(function (key) {
      formatted.times[key].time = moment(formatted.times[key].time, 'YYYY-MM-DD HH:mm:ss').seconds(0);
    });
  }

  if (item.incidentType) {
    formatted.incident_type = item.incidentType.data;
  }

  if (item.incidentSubtype) {
    formatted.incident_subtype = item.incidentSubtype.data;
  }

  if (item.deploymentReason) {
    formatted.deployment_reason = item.deploymentReason.data;
  }

  if (item.ambulanceService) {
    formatted.ambulance_service = item.ambulanceService.data;
  }

  if (item.refusalReason) {
    formatted.refusal_reason = item.refusalReason.data;
  }

  if (item.serviceArea) {
    formatted.service_area = item.serviceArea.data;
  }

  if (item.deployments) {
    formatted.deployments = item.deployments.data.map(formatDeployment);
  }

  if (item.flaggedIncidents) {
    formatted.flagged_incidents = item.flaggedIncidents.data.map(formatFlaggedIncident);
  }

  return formatted;
}

export default class IncidentResource extends RestResource {
  init () {
    super.init();
    this.resourcePath = 'incidents';
  }

  parse (item) {
    return parse(item);
  }

  format (item) {
    return format(item);
  }
}
