import uuid from 'node-uuid';

export default class CreateIncidentTypeController {
  constructor ($rootScope, Utils, Dialog) {
    'ngInject';
    this._Utils = Utils;
    this._Dialog = Dialog;
    this.$scope = $rootScope.$new();

    if (!this.categoryData) {
      this.categoryData = {};
    }

    if (!this.categoryData.subtypes) {
      this.categoryData.subtypes = [];
    }

    let previousSubtypes = this.categoryData.subtypes.map(item => ({ ...item }));
    this.$scope.$watch(() => this.categoryData.is_trauma, (newVal) => {
      if (newVal) {
        this.categoryData.subtypes = previousSubtypes;
      } else {
        previousSubtypes = this.categoryData.subtypes.map(item => ({ ...item }));
        this.categoryData.subtypes.forEach(subtype => (subtype.is_penetrating_trauma = 0));
      }
    });

    this.INCIDENT_TYPE_ID = uuid.v4();

    this.includePath = 'pages/admin/categories/templates/forms/incident-type.tpl.html';
  }

  addSubtype () {
    this.categoryData.subtypes.push({
      id: uuid.v4(),
    });
  };

  removeSubtype (item) {
    this._Utils.removeFromArray(this.categoryData.subtypes, item);
  };

  submit (categoryData) {
    if (this.form.$invalid) {
      return;
    }

    if (this.editing) {
      this._update(categoryData);
    } else {
      this._create(categoryData);
    }
  };

  cancel () {
    this._Dialog.cancel();
  };

  delete (id) {
    this._Dialog.confirm().then(() => {
      this.resource.destroy(id).then(() => {
        this._Dialog.cancel(id);
      });
    });
  };

  _create (categoryData) {
    categoryData.id = this.INCIDENT_TYPE_ID;

    this.resource.create(categoryData).then(() => {
      this._Dialog.hide(categoryData);
    }).catch(console.log.bind(console));
  };

  _update (categoryData) {
    this.resource.update(categoryData.id, categoryData).then((category) => {
      this._Dialog.hide(category);
    }).catch(console.log.bind(console));
  };
}
