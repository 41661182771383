import RestResource from 'common/resource/services/restResource';

export default class KpiReportResource extends RestResource {
  init () {
    super.init();
    this.resourcePath = 'kpi-report';
  }

  get (from, to) {
    const queryString = this._generateQueryString({ date_from: from, date_to: to });
    const url = `${this.apiUrl}${this.resourcePath}${queryString}`;
    return this._http.get(url).then(res => res.data.data);
  }
}
