import moment from 'moment';

export default function format (item) {
  const dateTime = moment(item.time, 'YYYY-MM-DD HH:mm:ss').seconds(0).toDate();

  return {
    id: item.id,
    dose: parseFloat(item.dose),
    time: dateTime,
    date: dateTime,
    drug: item.drug.data,
  };
}
