import angular from 'angular';
import uiRouter from 'angular-ui-router';
import ModuleConfig from './module.config';

import AlertablesPage from './components/alertables-page/alertables-page.component';
import AlertableUsersPage from './components/alertable-users-page/alertable-users-page.component';

const module = angular
  .module('gnaas.pages.admin.alerts', [
    uiRouter,
  ])
  .config(ModuleConfig)
  .component('alertablesPage', AlertablesPage)
  .component('alertableUsersPage', AlertableUsersPage);

export default module.name;
