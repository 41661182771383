const SIDENAV = 'sidenav-main';

const CONSUMABLE_SUBMENU = [
  { name: 'Consumable List', sref: 'admin.consumables.list' },
  { name: 'Consumable Stock', sref: 'admin.consumables.stock' },
];

const DRUG_SUBMENU = [
  { name: 'Batch Management', sref: 'admin.drugs.batchManagement.allDrugs' },
  { name: 'Transaction History', sref: 'admin.drugs.transactionHistory' },
  { name: 'Stock Checks', sref: 'admin.drugs.stockChecks.list' },
];

function generateMainMenu () {
  return [
    { name: 'Dashboard', sref: 'dashboard' },
    { name: 'Map', sref: 'map' },
    { name: 'Request Log', sref: 'requestLog' },
    { name: 'Calendar', sref: 'calendar' },
    { name: 'Crew Members', sref: 'crewMembers' },
  ];
}

function generateMainMenuWithReports () {
  return [
    ...generateMainMenu(),
    { name: 'Audit Tool', sref: 'auditTool' },
    { name: 'Reports', sref: 'reports' },
  ];
}

function generateMainMenuWithReportsAndKPIs () {
  return [
    ...generateMainMenuWithReports(),
    { name: 'KPI', sref: 'kpi' },
    { name: 'KPI Report', sref: 'kpi-report' },
  ];
}

function generateMainMenuForAdmins () {
  return [
    ...generateMainMenuWithReportsAndKPIs(),
    { name: 'Shifts', sref: 'shifts' },
  ];
}

function generateAdminMenu (categoryMap) {
  const categories = Object.keys(categoryMap)
    .sort()
    .map(category => {
      return {
        name: categoryMap[category].labels.multiple,
        sref: 'admin.category',
        srefParams: { categoryName: category },
      };
    });

  const drugSubmenu = [{ name: 'Drug List', sref: 'admin.drugs.drugList' }, ...DRUG_SUBMENU];

  const alertsSubmenu = [
    { name: 'Drugs', sref: 'admin.alerts.alertables', srefParams: { type: 'drug' } },
    { name: 'Interventions', sref: 'admin.alerts.alertables', srefParams: { type: 'intervention' } },
    { name: 'Recipients', sref: 'admin.alerts.users' },
  ];

  const reportablesSubmenu = [
    { name: 'Drugs', sref: 'admin.reports.reportables', srefParams: { type: 'drug' } },
    { name: 'Interventions', sref: 'admin.reports.reportables', srefParams: { type: 'intervention' } },
  ];

  return [
    { name: 'Alerts', parentSref: 'admin.alerts', children: alertsSubmenu },
    { name: 'Bases', sref: 'admin.bases' },
    { name: 'Categories', children: categories },
    { name: 'Consumables', parentSref: 'admin.consumables', children: CONSUMABLE_SUBMENU },
    { name: 'ARC Devices', sref: 'admin.devices' },
    { name: 'Drugs', sref: 'admin.drugs', children: drugSubmenu },
    { name: 'Equipment Locations', sref: 'admin.equipmentLocations.list' },
    { name: 'Flagged Incidents', sref: 'admin.flaggedIncidents' },
    { name: 'Hospitals', sref: 'admin.hospitals' },
    { name: 'Reportable Items', sref: 'admin.reports', children: reportablesSubmenu },
    { name: 'Vehicles', sref: 'admin.vehicles' },
    { name: 'Vehicle Callsigns', sref: 'admin.vehicleCallsigns' },
  ];
}

function generateMedicAdminMenu () {
  return [
    { name: 'Consumable Stock', sref: 'admin.consumables.stock' },
    { name: 'Drugs', parentSref: 'admin.drugs', children: DRUG_SUBMENU },
    { name: 'Equipment Locations', sref: 'admin.equipmentLocations.list' },
  ];
}

function generateOpsAdminsAdminMenu () {
  return [
    { name: 'Consumable Stock', sref: 'admin.consumables.stock' },
  ];
}

function generateCharityMainMenu () {
  return [
    { name: 'Dashboard', sref: 'dashboard' },
    { name: 'Calendar', sref: 'calendar' },
  ];
}

function generateTarnMainMenu () {
  return [
    { name: 'Request Log', sref: 'requestLog' },
  ];
}

function generateReportingMainMenu () {
  return [
    { name: 'Dashboard', sref: 'dashboard' },
    { name: 'Calendar', sref: 'calendar' },
    { name: 'Reports', sref: 'reports' },
  ];
}

export class SidenavMenuController {
  /**
   * @constructor
   * @ngInject
   */
  constructor ($state, $stateParams, $mdSidenav, Session, CATEGORY_MAP, APP_ORG_SHORT) {
    this._state = $state;
    this._stateParams = $stateParams;
    this._mdSidenav = $mdSidenav;
    this._Session = Session;
    this._CATEGORY_MAP = CATEGORY_MAP;
    this.APP_ORG_SHORT = APP_ORG_SHORT;
  }

  $onInit () {
    this._menu = [];
    this._adminMenu = [];

    this.user = this._Session.user();

    switch (this.user.group.slug) {
      case 'system-administrators':
        this._menu = generateMainMenuForAdmins();
        this._adminMenu = generateAdminMenu(this._CATEGORY_MAP);
        break;
      case 'medical-staff':
        this._menu = generateMainMenuWithReportsAndKPIs();
        this._adminMenu = generateMedicAdminMenu();
        break;
      case 'ops-admins':
        this._menu = generateMainMenuWithReports();
        this._adminMenu = generateOpsAdminsAdminMenu();
        break;
      case 'charity-staff':
        this._menu = generateCharityMainMenu();
        break;
      case 'tarn':
        this._menu = generateTarnMainMenu();
        break;
      case 'reporting':
        this._menu = generateReportingMainMenu();
        break;
      default:
        this._menu = generateMainMenu();
        break;
    }
  }

  isActive (sref, srefParams) {
    if (srefParams && srefParams.categoryName) {
      return this._state.current.name === sref && this._stateParams.categoryName === srefParams.categoryName;
    } else if (srefParams && srefParams.type) {
      return this._state.current.name === sref && this._stateParams.type === srefParams.type;
    } else {
      return this._state.current.name === sref;
    }
  }

  sidenavIsLockedOpen () {
    return this._mdSidenav(SIDENAV).isLockedOpen();
  }

  closeSidenav () {
    this._mdSidenav(SIDENAV).close();
  }

  getMenu () {
    return this._menu;
  }

  getAdminMenu () {
    return this._adminMenu;
  }

  goto (state, params) {
    this._state.go(state, params).then(() => {
      if (!this.sidenavIsLockedOpen()) {
        this.closeSidenav();
      }
    });
  }
}

export default {
  templateUrl: 'common/navigation/templates/sidenavMenu.tpl.html',
  controller: SidenavMenuController,
};
