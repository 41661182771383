import angular from 'angular';
import KpiResource from './resources/kpi.resource';
import KpiReportResource from './resources/kpi-report.resource';
import ReportResource from './resources/report.resource';
import ReportablesResource from './resources/reportables.resource';

const module = angular
  .module('gnaas.core.reports', [])
  .service('KpiResource', KpiResource)
  .service('KpiReportResource', KpiReportResource)
  .service('ReportResource', ReportResource)
  .service('ReportablesResource', ReportablesResource);

export default module.name;
