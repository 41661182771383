export default function integerValidator () {
  return {
    replace: false,
    require: 'ngModel',
    restrict: 'A',
    link: function ($scope, $element, $attrs, $ctrl) {
      $ctrl.$parsers.push(input => {
        if (!input || /^[0-9]*$/.test(input)) return input;
        $ctrl.$setViewValue($ctrl.$modelValue);
        $ctrl.$render();
        return $ctrl.$modelValue;
      });

      $ctrl.$formatters.push(value => {
        return parseInt(value, 10);
      });
    },
  };
};
