import formatAdministeredDrugs from './administered-drug-formatter.service';
import formatAssessedInjury from './assessed-injury-formatter.service';
import formatAssessedIllness from './assessed-illness-formatter.service';
import formatAdministeredIntervention from './administered-intervention-formatter.service';
import moment from 'moment';

export default function format (item) {
  const dateTime = moment(item.time, 'YYYY-MM-DD HH:mm:ss').seconds(0).toDate();

  let formatted = {
    id: item.id,
    time: dateTime,
    date: dateTime,
  };

  if (item.observations) {
    formatted.observations = item.observations.data;
    if (formatted.observations.blood_pressure_1) {
      formatted.observations.blood_pressure =
          formatted.observations.blood_pressure_1 + '/' + formatted.observations.blood_pressure_2;
      delete formatted.observations.blood_pressure_1;
      delete formatted.observations.blood_pressure_2;
    }
    if (formatted.observations.etco2) {
      formatted.observations.etco2 = parseFloat(formatted.observations.etco2);
    }
  } else {
    formatted.observations = {};
  }

  formatted.administered_drugs = item.administeredDrugs
      ? item.administeredDrugs.data.map(formatAdministeredDrugs)
      : [];

  formatted.assessed_injuries = item.assessedInjuries
      ? item.assessedInjuries.data.map(formatAssessedInjury)
      : [];

  formatted.assessed_illnesses = item.assessedIllnesses
      ? item.assessedIllnesses.data.map(formatAssessedIllness)
      : [];

  formatted.administered_interventions = item.administeredInterventions
      ? item.administeredInterventions.data.map(formatAdministeredIntervention)
      : [];

  return formatted;
}
