import { copy } from 'angular';
import uuid from 'node-uuid';
import moment from 'moment';
import deepEqual from 'deep-equal';

function createMarker (item) {
  const marker = { ...item.body_part.marker };

  if (item.intervention) {
    marker.class = 'green';
  } else if (item.status === 'amber') {
    marker.class = 'amber';
  } else if (item.status === 'red') {
    marker.class = 'red';
  }

  return marker;
}

class SurveysTabController {
  /**
   * @constructor
   * @ngInject
   */
  constructor (
    $q, $rootScope, $timeout, Dialog, IllnessDialog, InjuryDialog, InterventionDialog,
    Toast, SurveyResource
  ) {
    this.$q = $q;
    this.$rootScope = $rootScope;
    this.$timeout = $timeout;
    this.Dialog = Dialog;
    this.IllnessDialog = IllnessDialog;
    this.InjuryDialog = InjuryDialog;
    this.InterventionDialog = InterventionDialog;
    this.Toast = Toast;
    this.SurveyResource = SurveyResource;

    this.selectedSurveyOriginal = null;
    this.selectedSurveys = [];
    this.bodyMarkers = [];
    this.viewAllSurveys = false;
  }

  $onInit () {
    this.stateChangeListener = this.$rootScope.$on('$stateChangeStart', (e) => {
      if (!this.checkSelectedSurvey()) {
        e.preventDefault();
      }
    });
  }

  $onDestroy () {
    this.stateChangeListener();
  }

  $onChanges (changes) {
    const { isEditing, prf } = { ...changes };

    if (prf && prf.currentValue && prf.currentValue.surveys && prf.currentValue.surveys.length) {
      this.prf.surveys = this.prf.surveys.map(survey => {
        survey.deployment_status = this.calculateAssociatedDeploymentStatus(survey.time);
        return survey;
      });

      if (!this.selectedSurveys.length) {
        this.selectSurvey({ index: this.isEditing ? 0 : null });
      }
    }

    if (isEditing && this.prf && this.prf.surveys && this.prf.surveys.length) {
      this.selectSurvey({ index: isEditing.currentValue ? 0 : null });
    }
  }

  addSurvey () {
    if (!this.checkSelectedSurvey()) return false;
    if (!this.prf.surveys) {
      this.prf.surveys = [];
    }
    this.prf.surveys = [
      ...this.prf.surveys,
      {
        date: moment(this.prf.deployment.date).toDate(),
        observations: {
          id: uuid.v4(),
        },
        administered_drugs: [],
        assessed_injuries: [],
        assessed_illnesses: [],
        administered_interventions: [],
        patient_report_form: { id: this.prf.id },
        deployment_status: null,
      },
    ];
    this.selectSurvey({ index: this.prf.surveys.length - 1 });
    return true;
  }

  selectSurvey ({ index }) {
    if (!this.checkSelectedSurvey()) return false;

    if (index == null) {
      this.selectAllSurveys();
    } else {
      this.selectSingleSurvey(index);
    }
    this.updateMarkers(this.selectedSurveys);
    return true;
  }

  addDrugsToSelectedSurvey () {
    this.selectedSurveys[0].administered_drugs.push({
      id: uuid.v4(),
      date: moment(this.prf.deployment.date).toDate(),
    });
  }

  saveSelectedSurvey () {
    const survey = this.selectedSurveys[0];

    if (survey.observations.blood_pressure && !survey.observations.blood_pressure.match(/([0-9]\w*\/[0-9])\w*/)) {
      this.Dialog.alert('Please enter the B/P in the format: number/number', 'Invalid Blood Pressure format');
      return;
    }

    if (!survey.time) {
      this.Dialog.alert('Survey requires a timestamp.', 'Survey time missing.');
      return;
    }

    for (const item of survey.administered_drugs) {
      if (!item.drug || !item.dose || !item.time) {
        this.Dialog.alert('All drug info must be filled out before saving the survey.', 'Drug data missing.');
        return;
      }
    }

    if (moment(survey.date).isBefore(this.prf.deployment.date)) {
      this.Dialog.alert('Survey date must be on or after the deployment date.', 'Survey date incorrect.');
      return;
    }

    survey.administered_drugs.forEach(drug => {
      if (moment(drug.date).isBefore(this.prf.deployment.date)) {
        this.Dialog.alert(
          'Administered drugs date must be on or after the deployment date.',
          'Administered drugs date incorrect.'
        );
        return;
      }
    });

    if (survey.id) {
      this.updateSurvey(survey).then(() => {
        this.handleSurveySaved('Survey updated.');
      }).catch(() => {
        this.Toast.showSimple('Error updating survey.');
      });
    } else {
      this.createSurvey(survey).then(data => {
        this.selectedSurveys[0].id = data.id;
        this.handleSurveySaved('Survey created.');
      }).catch(() => {
        this.Toast.showSimple('Error creating survey.');
      });
    }
  }

  handleSurveySaved (toastMsg) {
    this.selectedSurveyOriginal = copy(this.selectedSurveys[0]);
    this.Toast.showSimple(toastMsg);
    if (typeof this.onPrfUpdate === 'function') {
      this.onPrfUpdate({ prf: this.prf });
    }
  }

  deleteSelectedSurvey () {
    const index = this.prf.surveys.indexOf(this.selectedSurveys[0]);

    this.Dialog.confirm('Once deleted, surveys can not be recovered.').then(() => {
      const survey = this.selectedSurveys[0];
      this.$q.when(survey.id ? this.SurveyResource.destroy(survey.id) : null)
        .then(data => {
          this.prf.surveys = this.prf.surveys.filter(item => {
            return data == null ? item !== survey : item.id !== survey.id;
          });
          this.handleSurveyDeleted(index);
        });
    });
  }

  handleSurveyDeleted (index) {
    this.clearSelectedSurvey();

    if (this.prf.surveys.length === 0) {
      return;
    }

    if (index > 0) {
      this.selectSurvey({ index: index - 1 });
    } else if (index === 0) {
      this.selectSurvey({ index: 0 });
    }

    this.Toast.showSimple('Survey deleted.');
    if (typeof this.onPrfUpdate === 'function') {
      this.onPrfUpdate({ prf: this.prf });
    }
  }

  discardSelectedSurveyChanges () {
    if (!this.isEditing || deepEqual(this.selectedSurveyOriginal, copy(this.selectedSurveys[0]))) { return; }
    this.Dialog.confirm('This will reset all the changes you have made on the selected survey.').then(() => {
      Object.assign(this.selectedSurveys[0], this.selectedSurveyOriginal);
    });
  }

  removeDrugRow (row) {
    this.selectedSurveys[0].administered_drugs =
      this.selectedSurveys[0].administered_drugs.filter(i => i.id !== row.id);
  }

  getSurveyOrderNumber (survey) {
    return this.prf.surveys.indexOf(survey) + 1;
  }

  selectAllSurveys () {
    this.surveyItems = [];
    this.viewAllSurveys = true;
    this.selectedSurveys = [...this.prf.surveys];
    this.selectedSurveys.forEach((survey, i) => {
      survey.type = 'survey';
      survey.surveyNumber = i + 1;
      this.surveyItems.push(survey);
      survey.administered_drugs.forEach(drug => {
        drug.type = 'drug';
        this.surveyItems.push(drug);
      });
    });
    this.selectedSurveyOriginal = null;
  }

  selectSingleSurvey (index) {
    this.surveyItems = [];
    this.viewAllSurveys = false;
    this.selectedSurveys = [this.prf.surveys[index]];
    this.selectedSurveys[0].surveyNumber = index + 1;
    this.selectedSurveyOriginal = copy(this.selectedSurveys[0]);
  }

  clearSelectedSurvey () {
    this.selectedSurveys = [];
    this.surveyItems = [];
    this.selectedSurveyOriginal = null;
  }

  checkSelectedSurvey () {
    if (!this.isEditing || !this.selectedSurveyOriginal) return true;

    if (
      this.selectedSurveys.length &&
      (!this.selectedSurveys[0].id || !deepEqual(this.selectedSurveyOriginal, copy(this.selectedSurveys[0])))
    ) {
      this.Dialog.alert(
        'Please save, delete or discard the changes on selected survey.',
        'Survey has been changed.'
      );
      return false;
    }

    return true;
  }

  showInjuryDialog ({ $event, data }) {
    const isEditing = data && data.id != null;
    const survey = this.selectedSurveys[0];
    this.InjuryDialog
      .show({
        $event: $event,
        item: data,
        relationData: { bodyParts: this.relationData.bodyParts },
      })
      .then(({ item, addAnother }) => {
        survey.assessed_injuries = this.handleCreateOrUpdate(isEditing, survey.assessed_injuries, item);
        if (addAnother) {
          const newItem = copy(item);
          delete newItem.id;
          delete newItem.notes;
          delete newItem.injury_type;
          this.showInjuryDialog({ $event, data: newItem });
        }
      }).catch(id => {
        survey.assessed_injuries = this.handleDelete(survey.assessed_injuries, id);
      }).finally(() => {
        this.updateMarkers([this.selectedSurveys[0]]);
      });
  }

  showIllnessDialog ({ $event, data }) {
    const isEditing = data && data.id != null;
    const survey = this.selectedSurveys[0];
    this.IllnessDialog
      .show({
        $event: $event,
        item: data,
        relationData: { bodyParts: this.relationData.bodyParts },
      })
      .then(({ item, addAnother }) => {
        survey.assessed_illnesses = this.handleCreateOrUpdate(isEditing, survey.assessed_illnesses, item);
        if (addAnother) {
          const newItem = copy(item);
          delete newItem.id;
          delete newItem.notes;
          delete newItem.illness_type;
          this.showIllnessDialog({ $event, data: newItem });
        }
      }).catch(id => {
        survey.assessed_illnesses = this.handleDelete(survey.assessed_illnesses, id);
      }).finally(() => {
        this.updateMarkers([this.selectedSurveys[0]]);
      });
  }

  showInterventionDialog ({ $event, data }) {
    const isEditing = data && data.id != null;
    const survey = this.selectedSurveys[0];
    this.InterventionDialog
      .show({
        $event: $event,
        item: data,
        relationData: {
          bodyParts: this.relationData.bodyParts,
          crewMembers: this.prf.deployment.crew_members.filter(i => i.type === 'medic'),
        },
      })
      .then(({ item, addAnother }) => {
        survey.administered_interventions =
          this.handleCreateOrUpdate(isEditing, survey.administered_interventions, item);
        if (addAnother) {
          const newItem = copy(item);
          delete newItem.id;
          delete newItem.notes;
          delete newItem.performed_by_third_party;
          if (newItem.attributes) {
            newItem.attributes.forEach(attribute => {
              delete attribute.value;
              delete attribute.intervention_attribute_value;
            });
          }
          this.showInterventionDialog({ $event,  data: newItem });
        }
      }).catch(id => {
        survey.administered_interventions = this.handleDelete(survey.administered_interventions, id);
      }).finally(() => {
        this.updateMarkers([this.selectedSurveys[0]]);
      });
  }

  //
  // BODY MARKERS
  //

  updateMarkers (surveys) {
    this.bodyMarkers = [].concat(...surveys.map(survey => [
      ...survey.administered_interventions.map(intervention => createMarker(intervention)),
      ...survey.assessed_injuries.map(injury => createMarker(injury)),
      ...survey.assessed_illnesses.map(illness => createMarker(illness)),
    ]));
  }

  handleCreateOrUpdate (isEditing, array, item) {
    if (isEditing) {
      return array.map(i => i.id === item.id ? { ...i, ...item } : i);
    }
    return [...array, item];
  }

  handleDelete (array, deletedItemId) {
    return array.filter(({ id }) => id !== deletedItemId);
  }

  calculateAssociatedDeploymentStatus (surveyTime) {
    const time = moment.isMoment(surveyTime) ? time : moment(surveyTime);
    const statuses = this.prf.deployment.deployment_statuses;
    let status = null;

    statuses.forEach((item, i) => {
      if (time.isAfter(moment(item.time)) && (statuses[i + 1] && time.isBefore(moment(statuses[i + 1].time)))) {
        status = item.deployment_status.name;
        return;
      }
    });

    return status;
  }

  createSurvey (surveyObject) {
    const survey = copy(surveyObject);

    survey.id = uuid.v4();
    survey.time = this.updateDatetime(survey.time, survey.date);
    survey.administered_drugs = survey.administered_drugs.map(drug => {
      drug.time = this.updateDatetime(drug.time, drug.date);
      return drug;
    });

    return this.SurveyResource.create(survey).then(() => survey);
  }

  updateSurvey (surveyObject) {
    const survey = copy(surveyObject);

    survey.time = this.updateDatetime(survey.time, survey.date);
    survey.administered_drugs = survey.administered_drugs.map(drug => {
      drug.time = this.updateDatetime(drug.time, drug.date);
      return drug;
    });

    return this.SurveyResource.update(survey.id, survey);
  }

  updateDatetime (time, date) {
    date = moment(date);
    return moment(time).set({
      'year': date.get('year'),
      'month': date.get('month'),
      'date': date.get('date'),
    }).toDate();
  }
}

export default {
  controller: SurveysTabController,
  templateUrl: 'pages/requestLog/components/survey-tab/survey-tab.tpl.html',
  bindings: {
    isEditing: '<',
    prf: '<',
    relationData: '<',
    onPrfUpdate: '&',
  },
};
