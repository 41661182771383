export default {
  bindings: {
    isEditing: '<',
    viewAllSurveys: '<',
    surveyItems: '<',
    surveys: '<',
    drugs: '<',
    onRemoveClick: '&',
  },
  templateUrl: 'core/prf/components/observations/observations.tpl.html',
};
