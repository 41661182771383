export default class PrfPageService {
  /**
   * @constructor
   * @ngInject
   */
  constructor (
    $q, BodyPartResource, DrugResource,
    PatientTypeResource, PRFResource
  ) {
    this._q = $q;
    this._BodyPartResource = BodyPartResource;
    this._DrugResource = DrugResource;
    this._PatientTypeResource = PatientTypeResource;
    this._PRFResource = PRFResource;
  }

  getRelationData () {
    return this._q.all({
      bodyParts: this._BodyPartResource.index(),
      drugs: this._DrugResource.index(),
      patientTypes: this._PatientTypeResource.index(),
    }).catch(console.log.bind(console));
  }

  getPrf (id) {
    return this._PRFResource.show(id, {
      include: [
        'approval', 'incident', 'stockTransactions', 'images', 'attachments',
        'deployment', 'deployment.users', 'deployment.vehicleCallsign',
        'surveys', 'surveys.observations', 'surveys.administeredDrugs',
        'surveys.assessedInjuries', 'surveys.assessedIllnesses', 'surveys.administeredInterventions',
        'deployment.deploymentStatuses', 'deployment.deploymentStatuses.deploymentStatus',
        'deployment.deploymentStatuses.destination',
      ],
    });
  }

  getTransportedDestination (deploymentStatuses) {
    const atHospital = deploymentStatuses.filter(item => !!item.deployment_status.is_at_hospital);
    if (atHospital.length) {
      const destination = atHospital[atHospital.length - 1].destination;
      return destination ? destination.name : '';
    }
    return null;
  }

  getPRFOutcome (transportedTo) {
    if (transportedTo !== '' && !transportedTo) {
      return 'Treated on scene';
    }

    let outcome = 'Transported to hospital';
    if (transportedTo !== '') {
      outcome += ' - ' + transportedTo;
    }
    return outcome;
  }

  getBreadcrumbRoutes (incidentId, deploymentId) {
    return [
      {
        title: 'Request Log',
        state: 'requestLog()',
      },
      {
        title: 'Incident',
        state: `incident({id: '${incidentId}'})`,
      },
      {
        title: 'Deployment',
        state: `deployments({id: '${deploymentId}'})`,
      },
      {
        title: 'PRF',
      },
    ];
  }
}
