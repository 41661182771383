class PatientDetailsTabController {
  /**
   * @constructor
   * @ngInject
   */
  constructor ($state, PatientDetailsDialog, Toast) {
    this.$state = $state;
    this.PatientDetailsDialog = PatientDetailsDialog;
    this.Toast = Toast;
  }

  showPatientDialog ($event, prf) {
    this.PatientDetailsDialog
      .show({
        $event,
        item: prf,
        relationData: this.relationData,
      })
      .then(item => {
        this.prf = { ...this.prf, ...item };
        this.Toast.showSimple('Patient details updated.');

        if (typeof this.onPrfUpdate === 'function') {
          this.onPrfUpdate({ prf: this.prf });
        }
      }).catch(prfId => {
        if (typeof prfId !== 'string' || !prfId) {
          return;
        }
        this.Toast.showSimple('PRF deleted.');
        this.$state.go('deployments', {id: this.prf.deployment.id});
    });
  }
}

export default {
  controller: PatientDetailsTabController,
  templateUrl: 'pages/requestLog/components/patient-details-tab/patient-details-tab.tpl.html',
  bindings: {
    isEditing: '<',
    relationData: '<',
    prf: '<',
    onPrfUpdate: '&',
  },
};
