import moment from 'moment';

export default class RequestLogPageService {
  /**
   * @constructor
   * @ngInject
   */
  constructor (IncidentResource) {
    this._IncidentResource = IncidentResource;
  }

  getIncidentsForDateRange (startDate, endDate) {
    return this._IncidentResource.index({
      include: [
        'incidentType', 'incidentSubtype', 'refusalReason',
        'deployments', 'deployments.deploymentStatuses',
        'deployments.deploymentStatuses.deploymentStatus',
        'deployments.vehicleCallsign', 'deployments.vehicle',
        'deployments.patientReportForms',
      ],
      start_date: startDate,
      end_date: endDate,
    });
  }

  getWeekRange (date) {
    return moment.range(
      moment(date).startOf('week'),
      moment(date).endOf('week')
    );
  }

  getDays (range) {
    let days = [];
    range.by('days', (day) => {
      day.incidents = { accepted: [], declined: [] };
      days.push(day);
    });
    return days.reverse();
  }
}
