import moment from 'moment';

export default class DeploymentPageService {
  /**
   * @constructor
   * @ngInject
   */
  constructor (
    $q, CrewMemberResource, PrfPageService, DeploymentResource, DeploymentStatusResource,
    VehicleCallsignResource, HospitalResource, LocationResource, BaseResource
  ) {
    this._q = $q;
    this._CrewMemberResource = CrewMemberResource;
    this._PrfPageService = PrfPageService;
    this._DeploymentResource = DeploymentResource;
    this._DeploymentStatusResource = DeploymentStatusResource;
    this._VehicleCallsignResource = VehicleCallsignResource;
    this._HospitalResource = HospitalResource;
    this._LocationResource = LocationResource;
    this._BaseResource = BaseResource;
  }

  getDeployment (id) {
    return this._DeploymentResource.show(id, {
      include: [
        'deploymentStatuses', 'deploymentStatuses.deploymentStatus', 'deploymentStatuses.destination',
        'incident', 'incident.deployments', 'incident.deployments.vehicleCallsign',
        'patientReportForms', 'patientReportForms.surveys',
        'vehicleCallsign', 'users',
      ],
    });
  }

  getRelationData () {
    const promises = {
      deploymentStatuses: this._DeploymentStatusResource.index(),
      users: this._CrewMemberResource.index({
        filter: 'operational-crew',
        include: 'group,profile.profession,profile.image',
      }),
      vehicleCallsigns: this._VehicleCallsignResource.index({include: [
        'vehicle',
        'deploymentStatusUpdates:limit(1):order(time)',
        'deploymentStatusUpdates.deploymentStatus',
      ]}),
    };

    return this._q.all(promises);
  }

  getDestinationData () {
    const promises = {
      hospitals: this._HospitalResource.index(),
      locations: this._LocationResource.index(),
      bases: this._BaseResource.index(),
    };

    return this._q.all(promises);
  }

  getDeploymentOutcomes (deployment) {
    let outcomes = [];
    deployment.deployment_statuses.forEach(status => {
      if (outcomes.includes(status.deployment_status.name)) {
        return;
      }

      if (status.deployment_status.is_stand_down ||
        status.deployment_status.is_diverted ||
        status.deployment_status.name.toLowerCase().indexOf('aborted') !== -1
      ) {
        outcomes.push(status.deployment_status.name);
      }
    });

    deployment.patient_report_forms.forEach(item => {
      let transportedTo = this._PrfPageService.getTransportedDestination(deployment.deployment_statuses);
      item.outcome = this._PrfPageService.getPRFOutcome(transportedTo);
      if (outcomes.includes(item.outcome)) {
        return;
      }

      outcomes.push(item.outcome);
    });

    return this._generateOutcomeString(outcomes);
  }

  generateTimeline (deploymentStatuses) {
    return deploymentStatuses.map(status => {
      return {
        time: status.time,
        label: status.deployment_status.name,
      };
    });
  }

  calculateMobileTime (deploymentStatuses) {
    deploymentStatuses.sort((a, b) => {
      return a.time > b.time ? 1 : -1;
    });

    let mobileTime = 0;

    deploymentStatuses.forEach((status, i) => {
      let nextStatus = deploymentStatuses[i + 1];
      if (status.deployment_status.is_mobile && nextStatus) {
        mobileTime += moment(nextStatus.time).diff(moment(status.time), 'minutes');
      }
    });

    return mobileTime;
  }

  calculateResponseTime (timeToOrg, deploymentStatuses) {
    deploymentStatuses.sort((a, b) => {
      return a.time > b.time ? 1 : -1;
    });

    let responseTime = 0;
    const mobileStatuses = deploymentStatuses.filter(status => status.deployment_status.is_mobile);

    if (mobileStatuses.length) {
      let statusTime = moment(mobileStatuses[0].time);
      let ttg = moment(timeToOrg);

      statusTime.year(ttg.year()).month(ttg.month()).date(ttg.date());
      responseTime = statusTime.diff(ttg, 'minutes');
    }

    return responseTime;
  }

  _generateOutcomeString (outcomes) {
    let string = '';
    outcomes.forEach((outcome, i) => {
      string += outcome && i !== (outcomes.length - 1) ? outcome + ', ' : outcome;
    });
    return string;
  }
}
