import { copy } from 'angular';
import moment from 'moment';

function formatValue (value) {
  return typeof value.getMonth === 'function' ? moment(value).format('YYYY-MM-DD') : value;
};

function isValidCriteria (item) {
  return item.criteria && item.operation && item.value && (item.operation === 'between' ? item.secondValue : true);
}

function formatCriteriaItem (item) {
  const criteriaItem = {
    criteria: item.criteria.name,
    type: item.criteria.type,
    operation: item.operation,
    value: formatValue(item.value),
  };

  if (item.secondValue) {
    criteriaItem.secondValue = formatValue(item.secondValue);
  }

  return criteriaItem;
}

function formatCriteria (query) {
  return Object.keys(query)
    .reduce((acc, curr) => acc.concat(query[curr].criteria), [])
    .filter(isValidCriteria)
    .map(formatCriteriaItem);
}

function formatColumnsToDisplay (query) {
  return Object.keys(query).reduce((acc, curr) => {
    return query[curr].columnsToDisplay ? acc.concat(query[curr].columnsToDisplay) : acc;
  }, []).map(item => item.name);
}

export class SearchCriteriaController {

  /**
   * @constructor
   * @ngInject
   */
  constructor (Dialog, Toast, ColumnsToDisplayDialog, AuditToolResource) {
    this._Dialog = Dialog;
    this._Toast = Toast;
    this._ColumnsToDisplayDialog = ColumnsToDisplayDialog;
    this._AuditToolResource = AuditToolResource;
  }

  $onInit () {
    this.query = this.query || {};
  }

  addCriteria () {
    if (this.query.hasOwnProperty(this.categoryName)) {
      this.query[this.categoryName].criteria = [...this.query[this.categoryName].criteria, {}];
    } else {
      this.query[this.categoryName] = { criteria: [{}] };
    }
  }

  removeCriteria (row) {
    this.query[this.categoryName].criteria = this.query[this.categoryName].criteria.filter(item => row !== item);
  }

  clearAllCriteria () {
    this._Dialog.confirm('Are you sure you want to clear all filters?').then(() => {
      Object.keys(this.query).forEach(key => {
        delete this.query[key];
      });
      this._Toast.showSimple('All filters cleared successfully.');
    });
  }

  showColumnsToDisplayElements (categoryName) {
    return ['incidents', 'deployments', 'patients'].indexOf(categoryName.toLowerCase()) > -1;
  }

  showColumnsToDisplayDialog (evt, data) {
    if (!this.query[this.categoryName]) {
      this.query[this.categoryName] = {
        criteria: [],
        columnsToDisplay: [],
      };
    }

    const dialogProps = {
      $event: evt,
      item: copy(data),
      locals: {
        // TODO This should be handled on the server side
        category: this.category.filter(item => [
          'pivot_deployment_user.user_id',
          'pivot_administered_interventions_users.user_id',
        ].indexOf(item.name) === -1),
        query: this.query[this.categoryName],
      },
    };

    this._ColumnsToDisplayDialog.show(dialogProps).then(data => {
      this.query[this.categoryName].columnsToDisplay = data.map(item => {
        return {
          id: item.id,
          name: item.name,
          title: item.title,
        };
      });
    });
  }

  search () {
    if (this.form.$invalid) {
      this._Dialog.alert('Please check your search criteria.');
      return false;
    }

    if (!this._hasColumnsToDisplay()) {
      this._Dialog.alert('Please select at least one column to display.');
      return false;
    }

    const criteria = formatCriteria(this.query);
    const columnsToDisplay = formatColumnsToDisplay(this.query);
    this.onSearch({$event: {criteria, columnsToDisplay}});
  }

  _hasColumnsToDisplay () {
    return Object.keys(this.query)
      .filter(key => this.query[key].columnsToDisplay && this.query[key].columnsToDisplay.length)
      .length > 0;
  }
}

export default {
  bindings: {
    category: '<',
    categoryName: '@',
    operations: '<',
    values: '<',
    query: '<',
    onSearch: '&',
  },
  controller: SearchCriteriaController,
  templateUrl: 'core/search/components/search-criteria/search-criteria.tpl.html',
};
