export default function SearchInput () {
  return {
    restrict: 'E',
    replace: true,
    scope: {
      model: '=seModel',
      type: '@seType',
      name: '@seName',
      placeholder: '@sePlaceholder',
      errors: '=seErrors',
      label: '@seLabel',
      required: '=seRequired',
      disabled: '=seDisabled',
    },
    controller: function () {
      this.placeholder = this.placeholder || 'Enter value';
    },
    controllerAs: '$ctrl',
    bindToController: true,
    templateUrl: 'core/search/directives/search-input/search-input.tpl.html',
  };
}
