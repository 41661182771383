export default {
  bindings: {
    item: '<',
  },
  controller: function (DeploymentPageService, Session) {
    'ngInject';
    this.deployments = [];
    if (this.item.deployments) {
      this.deployments = this.item.deployments.map(deployment => {
        deployment.outcome = DeploymentPageService.getDeploymentOutcomes(deployment);
        if (deployment.deployment_statuses && deployment.deployment_statuses.length) {
          const index = deployment.deployment_statuses.length - 1;
          deployment.deploymentTime = deployment.deployment_statuses[index].time.format('HH:mm');
        }
        return deployment;
      });
    }

    this.hasPermissionToViewDeployments = () => {
      const user = Session.user();
      if (!user || !user.group) return false;
      return ['system-administrators', 'medical-staff', 'ops-admins', 'pilots', 'tarn'].indexOf(user.group.slug) > -1;
    };
  },
  templateUrl: 'pages/requestLog/components/requestListItem.tpl.html',
};
